body {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
  font-size: 2.25rem;
  background-image: url(https://images.unsplash.com/photo-1585314062340-f1a5a7c9328d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80);
  background-color: #000;
}

:root {
  --d: 2500ms;
  --angle: 90deg;
  --gradX: 100%;
  --gradY: 50%;
  --c1: rgb(38, 57, 77);
  --c2: rgba(252, 252, 252, 0.1);
}

.animatedBorder {
  position: relative;
  background: linear-gradient(180deg, var(--c1), var(--c2));
  animation: pulseBackgroundColor var(--d) linear infinite forwards;
  border-radius: 0.25rem;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  overflow: hidden;
}

.animatedBorder::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
}

@keyframes pulseBackgroundColor {
  0%,
  100% {
    background-color: var(--c1);
  }
  50% {
    background-color: var(--c2);
  }
}

.img {
  border-radius: 10px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.customLink {
  margin: 0 4rem;
  font-size: 2.25rem;
}

.logo:active {
  transform: scale(0.9);
}
.customLink:active {
  font-size: calc(36px * 0.9);
}

.headerContainer {
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.footerStyle {
  box-shadow: rgb(38, 57, 77) 0px -20px 30px -10px;
}

.footerLinks {
  margin: 0 2rem;
}

.datepicker {
  z-index: 3000 !important;
}

/* @keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
} */

/* .modalContainer {
  position: fixed;
  top: 0;
  right: -60%;
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transition: slideIn 0.5s ease-in-out;
  z-index: 100;
} */

.booking-image-container .img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.custom-row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.custom-col {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* .carousel img {
  object-fit: cover;
  object-position: center;
  height: 100vh;
  overflow: hidden;
} */

.videoContainerStyle {
  background-color: #000;
  padding: 0.5rem;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.videoStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* removes carousel button icons and indicators */
/* .carousel-control-prev-icon,
.carousel-control-next, */
.carousel-indicators {
  display: none;
}
